export default {
  methods: {
    setReferrer () {
      try {
        const moment = this.$moment()
        const now = moment.unix()
        const referrer = (new URL(document.referrer || this.$config.appUrl)).hostname
        const app = (new URL(this.$config.appUrl)).hostname
        // console.log('Referrer: ', referrer)
        // console.log('App: ', app)

        if (['google.com', 'www.google.com'].includes(referrer) &&
          this.$route.query.srsltid &&
          this.resettable('google', now)
        ) {
          localStorage.setItem('referrer', JSON.stringify({
            id: this.$route.query.srsltid,
            medium: 'referral',
            type: 'google',
            ttl: moment.clone().add(30, 'days').unix()
          }))
        } else if (['google.com', 'www.google.com'].includes(referrer) &&
          this.$route.query.gclid &&
          this.resettable('google', now)
        ) {
          localStorage.setItem('referrer', JSON.stringify({
            id: this.$route.query.gclid,
            medium: 'cpc',
            type: 'google',
            ttl: moment.clone().add(30, 'days').unix()
          }))
        } else if (referrer === app &&
          this.$route.query.utm_source === 'salesdoubler' &&
          this.$route.query.aff_sub &&
          this.resettable('salesdoubler', now)
        ) {
          localStorage.setItem('referrer', JSON.stringify({
            id: this.$route.query.aff_sub,
            medium: 'referral',
            type: 'salesdoubler',
            ttl: moment.clone().add(30, 'days').unix()
          }))
        } else if (referrer === app &&
          this.$route.query.utm_source === 'admitad' &&
          this.$route.query.admitad_uid &&
          this.resettable('admitad', now)
        ) {
          localStorage.setItem('referrer', JSON.stringify({
            id: this.$route.query.admitad_uid,
            medium: 'referral',
            type: 'admitad',
            ttl: moment.clone().add(30, 'days').unix()
          }))
        } else if (['hotline.ua'].includes(referrer) &&
          this.$route.query.utm_source === 'hotline' &&
          this.resettable('hotline', now)
        ) {
          localStorage.setItem('referrer', JSON.stringify({
            id: this.$route.query.utm_content ?? null,
            medium: 'referral',
            type: 'hotline',
            ttl: moment.clone().add(30, 'days').unix()
          }))
        } else if (['ek.ua'].includes(referrer) &&
          this.$route.query.utm_source === 'e-katalog' &&
          this.resettable('ekatalog', now)
        ) {
          localStorage.setItem('referrer', JSON.stringify({
            id: this.$route.query.utm_term ?? null,
            medium: 'referral',
            type: 'ekatalog',
            ttl: moment.clone().add(30, 'days').unix()
          }))
        } else if (['vse.ua'].includes(referrer) &&
          this.$route.query.utm_source === 'vseua' &&
          this.resettable('vseua', now)
        ) {
          localStorage.setItem('referrer', JSON.stringify({
            id: this.$route.query.utm_campaign ?? null,
            medium: 'cpc',
            type: 'vseua',
            ttl: moment.clone().add(30, 'days').unix()
          }))
        } else if (['price.ua'].includes(referrer) &&
          this.$route.query.utm_source === 'priceua' &&
          this.resettable('priceua', now)
        ) {
          localStorage.setItem('referrer', JSON.stringify({
            id: this.$route.query.utm_content ?? null,
            medium: 'cpc',
            type: 'priceua',
            ttl: moment.clone().add(30, 'days').unix()
          }))
        } else if (['youtube.com'].includes(referrer) &&
        this.$route.query.utm_source === 'youtube' &&
          this.resettable('youtube', now)
        ) {
          localStorage.setItem('referrer', JSON.stringify({
            id: this.$route.query.utm_campaign ?? null,
            medium: 'referral',
            type: 'youtube',
            ttl: moment.clone().add(30, 'days').unix()
          }))
        } else if (referrer === app &&
          this.$route.query.utm_source === undefined &&
          this.resettable('direct', now)
        ) {
          localStorage.setItem('referrer', JSON.stringify({
            id: null,
            medium: 'internal',
            type: 'direct',
            ttl: moment.clone().add(1, 'days').unix()
          }))
        }
      } catch (e) {
        console.log(e)
      }
    },

    getReferrer () {
      const referrer = JSON.parse(localStorage.getItem('referrer'))

      if (typeof referrer === 'object' && referrer !== null && 'id' in referrer && 'type' in referrer) {
        return referrer
      } else {
        return null
      }
    },

    flushReferrer () {
      localStorage.removeItem('referrer')
    },

    resettable (type, now) {
      const referrer = JSON.parse(localStorage.getItem('referrer'))
      const isSet = typeof referrer === 'object' && referrer !== null && 'id' in referrer

      return !isSet || (isSet && (referrer.type !== type || referrer.ttl < now))
    }
  }
}
