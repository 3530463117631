import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      support: 'support'
    }),

    operating () {
      const timeFormat = 'hh:mm'
      const schedule = this.support.schedule.days
      let operating = {}

      if (Array.isArray(schedule)) {
        const moment = this.$moment().locale(this.$i18n.locale)
        const weekday = moment.isoWeekday()
        const open = this.$moment(schedule[weekday - 1].open, timeFormat)
        const close = this.$moment(schedule[weekday - 1].close, timeFormat)

        if (moment.isBefore(open) && schedule[weekday - 1].open && schedule[weekday - 1].close) {
          operating = {
            active: false,
            title: this.$t('support.today.notYet.title'),
            subtitle: this.$t('support.today.notYet.subtitle', {
              open: schedule[weekday - 1].open,
              close: schedule[weekday - 1].close
            }),
            class: 'has-text-warning'
          }
        } else if (moment.isBetween(open, close) && schedule[weekday - 1].open && schedule[weekday - 1].close) {
          operating = {
            active: true,
            title: this.$t('support.today.operating.title'),
            subtitle: this.$t('support.today.operating.subtitle', {
              close: schedule[weekday - 1].close
            }),
            class: 'has-text-success'
          }
        } else {
          for (let i = 1; i <= 7; i++) {
            let nextDay = weekday + i

            if (nextDay > 7) {
              nextDay -= 7
            }

            if (nextDay in schedule && schedule[nextDay - 1].open && schedule[nextDay - 1].close) {
              let title = ''

              if (i === 1 && (!schedule[weekday - 1].open || !schedule[weekday - 1].close)) {
                title += `${this.$t('support.today.dayOff.title')}<br>`
              } else if (i > 1) {
                title += `${this.$t('support.daysOff.title')}<br>`
              }

              if (i === 1) {
                title += this.$t('support.tomorrow.title')
              } else {
                title += this.$t('support.nextDay.title', {
                  weekday: this.$moment().isoWeekday(nextDay).format('dd').toUpperCase()
                })
              }

              operating = {
                active: false,
                title,
                subtitle: this.$t('support.nextDay.subtitle', {
                  open: schedule[nextDay - 1].open,
                  close: schedule[nextDay - 1].close
                }),
                class: 'has-text-warning'
              }

              break
            }
          }

          if (operating === undefined) {
            operating = {
              active: false,
              title: this.$t('support.today.dayOff.title'),
              subtitle: '',
              class: 'has-text-grey'
            }
          }
        }
      } else if (schedule === '') {
        operating = {
          active: true,
          title: this.$t('support.allDay.title'),
          subtitle: this.$t('support.allDay.subtitle'),
          class: 'has-text-grey'
        }
      }

      return operating
    }
  }
}
